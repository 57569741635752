import { req } from "../js/config";
import { ToHTML, ToHTMLWithFormatting, GetImageURLfromBlocks, GetNonImagefromBlocks, GetRawLinkFromPBlock } from "../js/base";
import { async } from "regenerator-runtime";

let PageData = ''
const landingPageColors = ['blue', 'purple', 'green', 'gold', 'red', 'orange', 'navy', 'pink', 'blue', 'purple', 'green', 'gold', 'red', 'orange', 'navy', 'pink', 'blue', 'purple', 'green', 'gold', 'red', 'orange', 'navy', 'pink', 'blue', 'purple', 'green', 'gold', 'red', 'orange', 'navy', 'pink']


const Initialize = async () => {
  //getPageData
  await getPageData();
  console.log("Initialize ~ PageData", PageData)
  await generatePageHTML();
}
const getPageData = async () => {

  console.log('getPageData from the server');
  PageData = await req(`Sections/landingPage`).catch(function (response) {
  });


  return PageData;
}

const renderRightTpl = (d) => {

  let tpl = `  
              <section class="space-pb ">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-6 col-md-6 mb-4 mb-md-0">
                    <img class="img-fluid rounded-sm" src="${GetImageURLfromBlocks(d.body1)}" alt="">
                    </div>
                    <div class="col-lg-6 mt-4 mt-lg-0">
                      <div class="section-title">
                      <h2>${d.header}</h2>
                      ${GetNonImagefromBlocks(d.body1)}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              `
  return tpl;
}

const renderLeftTpl = (d) => {
  console.log('render left sectin content', d)
  let tpl = `
            <section class="space-pb ">
              <div class="container">
                <div class="row">
                  <div class="col-lg-6 mt-4 mt-lg-0">
                    <div class="section-title">
                      <h2>${d.header}</h2>
                      ${GetNonImagefromBlocks(d.body1)}
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 mb-4 mb-md-0">
                    <img class="img-fluid rounded-sm" src="${GetImageURLfromBlocks(d.body1)}" alt="">
                  </div>
                </div>
              </div>
            </section>`
  //console.log('returning template for left section - ', tpl);
  return tpl;
}


const renderHeader = async (d) => {
  console.log('line no 68', d)



  let tpl = `
    <div class="row">
                 <div class="col-md-8 offset-md-2">
                   <div class="content text-center">  
                   ${ToHTML(d[0].body1)}
                    
                   </div>
                 </div>
               </div>`

  document.querySelector('#landingPageHeader').innerHTML = tpl;

}
const LinksSection = async (d) => {
  let divs = '';
  let i = 0;
  d.forEach(e => {

    divs += `
              
              
  <div class="item">
    <a href="${$.parseHTML(GetRawLinkFromPBlock(e.body1))[0].href}">
      <div class="categories-style-03 bg-${landingPageColors[i]}-overlay" style="background-image: url(' ${GetImageURLfromBlocks(e.body1)}'); background-size: cover;">
        <div class="categories-icon">
          <i class="${e.title} fa-4x"></i>
          <h6 class="categories-title text-white mt-4 mb-0">  ${GetNonImagefromBlocks(e.body1)} </h6>
        </div>
      </div>
    </a>  
  </div>`




    i++;

  })


  return divs;
}
const renderLinks = async (d) => {
  console.log('line no 27', d)
  let tpl = `
  ${await LinksSection(d)}
  `

  document.querySelector('#landingPageLinks').innerHTML = tpl;

}

const renderDynamicSections = async (d) => {
  console.log('render dynamic sections =-', d)
  let divs = '';
  console.log('1')
  d.forEach((e) => {
    if (e.pageId.includes('landingPage-SectionLeft')) {
      divs += renderLeftTpl(e);

    }
    else if (e.pageId.includes('landingPage-SectionRight')) {
      divs += renderRightTpl(e);
    }
  })

  //console.log('html going to div - ', divs)
  document.querySelector('#dynamicSections').innerHTML = divs;

}
const Footer_Tpl = async (d) => {
  let divs = '';

  d.forEach(e => {

    divs += `




    <div class="col-sm-6 col-xl-3 col-lg-4 mb-4 mb-lg-0">
    <h5 class="footer-title">${e.title}</h5>
    <div class="footer-contact-info" >
      
    ${ToHTMLWithFormatting(e.body1)}
    </div>
    </div>
    `
  })


  return divs;
}

const renderFooter = async (d) => {
  console.log('line no 142', d)



  let tpl = `

               
               ${await Footer_Tpl(d)}
               
                 
               <div class="col-sm-6 col-lg-3">
               <h5 class="footer-title">Map</h5>
               <div class="widget-content">
                 <iframe class="w-100 border-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184465.4757844205!2d-79.9163597906571!3d43.746426142173334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe9ab8bff7b4a0d1d!2sXyna%20International%20School!5e0!3m2!1sen!2sca!4v1610657939570!5m2!1sen!2sca" style="border:0; width: 100%; height: 250px;" allowfullscreen="" data-gtm-yt-inspected-9="true">
                 </iframe>                   </div>
             </div>
               
               
               
               
               
               `

  document.querySelector('#landingPageFooter').innerHTML = tpl;

}
const generatePageHTML = async () => {
  //change picture here
  //console.log('*************** image path is ', GetImageURLfromBlocks(PageData.data.filter(f => f.pageId.includes('landingPage-MainPicture'))[0].body1))
  console.log('~~~~~~~~~binding Image ~~~~~~~~~')
  document.querySelector("#headerMainPic").style.background = "url(" + GetImageURLfromBlocks(PageData.data.filter(f => f.pageId.includes('landingPage-MainPicture'))[0].body1) + ") ";

  //render all sections here
  console.log('~~~~~~~~~binding Header~~~~~~~~~')
  await renderHeader(PageData.data.filter(f => f.pageId.includes('landingPage-Header')));

  console.log('~~~~~~~~~binding links~~~~~~~~~')
  await renderLinks(PageData.data.filter(f => f.pageId.includes('landingPage-Links')));

  console.log('~~~~~~~~~binding sections~~~~~~~~~')
  await renderDynamicSections(PageData.data.filter(f => f.pageId.includes('landingPage-Section')));

  console.log('~~~~~~~~~binding footer~~~~~~~~~')
  await renderFooter(PageData.data.filter(f => f.pageId.includes('landingPage-Footer')));

  console.log('~~~~~~~~~binding carousel~~~~~~~~~')
  var owlslider = jQuery("div.owl-carousel");
  if (owlslider.length > 0) {
    owlslider.each(function () {
      var $this = $(this),
        $items = ($this.data('items')) ? $this.data('items') : 1,
        $loop = ($this.attr('data-loop')) ? $this.data('loop') : true,
        $navdots = ($this.data('nav-dots')) ? $this.data('nav-dots') : false,
        $navarrow = ($this.data('nav-arrow')) ? $this.data('nav-arrow') : false,
        $autoplay = ($this.attr('data-autoplay')) ? $this.data('autoplay') : true,
        $autospeed = ($this.attr('data-autospeed')) ? $this.data('autospeed') : 5000,
        $smartspeed = ($this.attr('data-smartspeed')) ? $this.data('smartspeed') : 1000,
        $autohgt = ($this.data('autoheight')) ? $this.data('autoheight') : false,
        $space = ($this.attr('data-space')) ? $this.data('space') : 30,
        $animateOut = ($this.attr('data-animateOut')) ? $this.data('animateOut') : false;

      $(this).owlCarousel({
        loop: $loop,
        items: $items,
        responsive: {
          0: {
            items: $this.data('xx-items') ? $this.data('xx-items') : 1
          },
          480: {
            items: $this.data('xs-items') ? $this.data('xs-items') : 1
          },
          768: {
            items: $this.data('sm-items') ? $this.data('sm-items') : 2
          },
          980: {
            items: $this.data('md-items') ? $this.data('md-items') : 3
          },
          1200: {
            items: $items
          }
        },
        dots: $navdots,
        autoplayTimeout: $autospeed,
        smartSpeed: $smartspeed,
        autoHeight: $autohgt,
        margin: $space,
        nav: $navarrow,
        navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
        autoplay: $autoplay,
        autoplayHoverPause: true
      });
    });
  }


}

const generateTPL_MessageSubmitted = () => {
  var tpl = `<div class="row col-sm-12"><h3 class="text-dark">Message Sent!</h3> </div>
  <div class="row col-sm-12"><p class="lead font-weight-bold mb-4 mb-md-5">Thank you for reaching out ${document.querySelector('#landing_Name').value}. We will get back to you.</p></div>`
  document.querySelector('#landingPage_ContactForm').innerHTML = tpl;
}

const submitLandingPageContactForm = async (token) => {
  console.log('submit form from the landing page');
  let contactInfo = {};
  contactInfo.token = token;
  contactInfo.Name = document.querySelector('#landing_Name').value;
  contactInfo.Email = document.querySelector('#landing_Email').value;
  contactInfo.Subject = document.querySelector('#landing_Subject').value;
  contactInfo.Phone = document.querySelector('#landing_Phone').value;
  contactInfo.Message = document.querySelector('#landing_Message').value
  req.post('Contact/LandingPageContactUsAsync', JSON.stringify(contactInfo)).then((res) => {
    console.log(res);
    generateTPL_MessageSubmitted();
  })

}

document.querySelector('#landingPage_ContactForm').addEventListener("submit", async (e) => {
  e.preventDefault();


  grecaptcha.ready(function () {
    grecaptcha.execute('6LeHbFkaAAAAAMBWIOhBNaXNy9roQGwThYe33UzY', { action: 'submit' }).then(async function (token) {
      // Add your logic to submit to  backend server.

      await submitLandingPageContactForm(token);
    });
  });

});

Initialize();

